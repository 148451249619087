import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const expensesCategoryComputed = {
    ...mapState('expensescategory', {
        expenses:(state) => state.expenses_categories,
    }),
    ...mapGetters('expensescategory', ['getExpenses'])
}

// export property actions
export const expensesCategoryMethods = mapActions('expensescategory', ['allExpensesCategory','createExpensesCategory', 'updateExpensesCategory', 'deleteExpensesCategory',
])
